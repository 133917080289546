<template>
  <page fluid="lg" :title="title">
    <formulario :adelantado="adelantado" :rezagado="rezagado" :simulacion="simulacion" @submit="actualizar">
    </formulario>
  </page>
</template>

<script>
import Formulario from "./Formulario.vue";
import * as apiIndicadores from "@/services/indicadores.js";
import * as apiSimulaciones from "@/services/simulaciones.js";

export default {
  components: { Formulario },
  computed: {
    title() {
      return `${this.adelantado.nombre}: Editar simulación para ${this.rezagado.nombre}`;
    },
  },
  data() {
    return {
      adelantado: {},
      rezagado: {},
      indicador: {},
    };
  },
  methods: {
    async cargar() {
      this.simulacion = await apiSimulaciones.cargar(this.$route.params.id);

      const rs = await Promise.all([
        apiIndicadores.cargar(this.simulacion.adelantadoId),
        apiIndicadores.cargar(this.simulacion.rezagadoId),
      ]);

      this.adelantado = rs[0];
      this.rezagado = rs[1];
    },
    async actualizar() {
      let ok = await apiSimulaciones.actualizar(this.simulacion);
      if (ok) {
        this.$emit("userMessage", "Se ha actualizado la simulación");
        const qs = Object.assign({}, this.$route.query);
        delete qs["id"];
        const params = new URLSearchParams(qs).toString();
        this.$router.push(`/simulaciones?${params}`);
      }
    },
    async cargarIndicador() {},
  },
  mounted() {
    this.cargar();
  },
};
</script>
